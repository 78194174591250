import api from '../../../api/api'
import { _orderByName } from '../../../utils/formatter'

export default {
  list: async (active = null) => {
    let data = await api.get.agroindustrias(active)

    data = data.filter(item => {
      if (item.pessoa?.pessoajuridica) return item
    })

    data = data.map(item => {
      return {
        Nome: item.pessoa?.pessoajuridica.NomeFantasia ?? '',
        id: item.CodAgroindustria,
        ...item,
      }
    })

    return _orderByName({
      data,
      key: 'Nome',
    })
  },
}
